import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import './App.css';
import React, { useState, useEffect } from 'react';
import { BrowserRouter, Routes, Route, Navigate, useLocation } from "react-router-dom";
import Login from './Components/Login/Login';
import Login2 from './Components/Login/Login2';
import Login3 from './Components/Login/Login3';
import Home from './Components/Home/Home';
import Bookings from './Components/Bookings/Bookings';
import Vessels from './Components/Vessels/Vessels';
import VesselDetails from './Components/Vessels/VesselDetails';
import VesselDoors from './Components/Vessels/VesselDoors';
import VesselDoorDetails from './Components/Vessels/VesselDoorDetails';
import Operations from './Components/Operations/Operations';
import BookingsCalendar from './Components/Bookings/BookingsCalendar';
// import Header from './Layout/Header';
// import Header2 from './Layout/Header2';



import UserRoleProvider from './Contexts/UserRoleProvider';
import { GangwayReservationsProvider } from './Contexts/GangwayReservationsContext';
import OperationsMap from './Components/OperationsMap/OperationsMap';
import BookingResourcesProvider from './Contexts/BookingResourcesProvider';
import Reports from './Components/Reports/Reports';
import ReportImagesProvider from './Contexts/ReportImagesProvider';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import SelectedDateProvider from './Contexts/SelectedDateProvider';
import VesselDataProvider from './Contexts/VesselDataProvider';
import Schedule from './Components/OperationsMap/OperationsMapGangwayReservations';
import ConditionalHeader from './Layout/ConditionalHeader';
import { TideProvider } from './Contexts/TideContext';
import  {RenderFlagProvider }from './Contexts/RenderFlagContext';
import { initializeColorMaps } from './utils/ColorMaps';


function App() {
  const getToken = () => localStorage.getItem('jwtToken') || sessionStorage.getItem('jwtToken');
  const [authenticated, setAuthenticated] = useState(!!getToken());
  const [userPortName, setUserPortName] = useState('');

  const logout = () => {
    localStorage.removeItem('jwtToken');
    sessionStorage.removeItem('jwtToken');
    localStorage.removeItem('userRole');
    sessionStorage.removeItem('userRole');
    setAuthenticated(false);
  };

  useEffect(() => {
    initializeColorMaps();
  }, []);

  return (
    <UserRoleProvider>
      <ReportImagesProvider>
        <BookingResourcesProvider>
          <DndProvider backend={HTML5Backend}>
            <SelectedDateProvider>
              <VesselDataProvider>
                <GangwayReservationsProvider>
                  <TideProvider>
                  <RenderFlagProvider>
                      <BrowserRouter>
                     <ConditionalHeader authenticated={authenticated} logout={logout} />
                        <Routes>
                          <Route path="/home" element={<Home />} />
                          <Route path="/login" element={<Login setAuthenticated={setAuthenticated} setUserPortName={setUserPortName} />} />
                          <Route path="/login2" element={<Login2 setAuthenticated={setAuthenticated} setUserPortName={setUserPortName} />} />
                          <Route path="/login3" element={<Login3 setAuthenticated={setAuthenticated} setUserPortName={setUserPortName} />} />
                          <Route path="/" element={<Login2 setAuthenticated={setAuthenticated} setUserPortName={setUserPortName} />} />   
                          {/* Protected routes */}
                          {authenticated ? (
                            <>
                              <Route path="/bookings" element={<Bookings userPortName={userPortName} />} />
                              <Route path="/vessels" element={<Vessels />} />
                              <Route path="/vesselDetails/:id" element={<VesselDetails />} />
                              <Route path="/vesselDoors/:vesselIdParam" element={<VesselDoors />} />
                              <Route path="/vesselDoorDetails/:vesselIdParam/:vesselDoorIdParam" element={<VesselDoorDetails />} />
                              <Route path="/operations" element={<Operations />} />
                              <Route path="/operationsMap" element={<OperationsMap userPortName={userPortName} authenticated={authenticated}/>} />
                              <Route path="/reports" element={<Reports />} />
                              <Route path="/schedule" element={<Schedule />} />
                              <Route path="/testing" element={<BookingsCalendar />} />
                            </>
                          ) : (
                            <Route path="/bookings" element={<Navigate to="/login" replace />} />
                          )}
                        </Routes>

                      </BrowserRouter>
                      </RenderFlagProvider>
                  </TideProvider>
                </GangwayReservationsProvider>
              </VesselDataProvider>
            </SelectedDateProvider>
          </DndProvider>
        </BookingResourcesProvider>
      </ReportImagesProvider>
    </UserRoleProvider>
  );
}

export default App;
