import React from 'react'
import { Tooltip } from 'react-tooltip'

const GlobalFilter = ({ filter, setFilter }) => {
  return (
    <span
      //  data-tooltip-id="bookingPageIcons"
      //  data-tooltip-content="Search Bookings by Keyword"
      className="bookingLabels">
      Search: {''}
      <input className="globalFilter" value={filter || ''}
        onChange={(e) => setFilter(e.target.value)} />
      {/* <Tooltip 
          id="bookingPageIcons"
          place="top"
          className="bookingPageTooltip"
          /> */}
    </span>

  )
}

export default GlobalFilter;