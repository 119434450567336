import 'bootstrap/dist/css/bootstrap.css';
import React, { useState, useEffect, useContext, useRef } from 'react';
import axios from 'axios';
import DateTime from 'react-datetime';
import "react-datetime/css/react-datetime.css";
import Constants from '../../Constants';
import moment from 'moment';
import 'moment-timezone';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import UserRoleContext from '../../Contexts/UserRoleContext';
import {checkAuthenticateIssue} from "../Login/LoginUtil";
import {useNavigate} from "react-router-dom";
import { berthByFactor } from '../OperationsMap/OperationsMapBerthByFactor';


function AddNewBookingModal({ closeModal, fetchBookingListThisYear }) {
    const [formData, setFormData] = useState({
        vesselName: '',
        vesselId: '',
        vesselOrientation:'',
        passengers: '',
        crew: '',
        mooringDateTime: '',
        departureDateTime: '',
        status: '',
        cancellationReason: '',
        specialNote: '',
        contactDateTime: '',
        agent: '',
        portId: '',
        berthName: '',
        xCoordinateFactor: '',
        yCoordinateFactor: '',
        vesselNote:''
    });

    const [vesselNames, setVesselNames] = useState([]);
    const [berthNames, setBerthNames] = useState([]);
    const [bookingDateError, setBookingDateError] = useState(false);
    const [errors, setErrors] = useState({});

    const token = sessionStorage.getItem("jwtToken") || localStorage.getItem("jwtToken");
    const userPortId = sessionStorage.getItem("userPortId") || localStorage.getItem("userPortId");
    const { userRole } = useContext(UserRoleContext);
    const navigate = useNavigate();

    var yesterday = moment().subtract(1, 'day');
    var valid = function (current) {
        return current.isAfter(yesterday);
    }
    const modalRef = useRef(null);
    useEffect(() => {
        let handler = (e) => {
            if (modalRef.current && !modalRef.current.contains(e.target)) {
                closeModal()
            }
        };
        document.addEventListener("mousedown", handler)
        return () => {
            document.removeEventListener('mousedown', handler);
        };
    }, [closeModal]);

    useEffect(() => {

        const url = userRole === "Admin"
            ? `${Constants.BACK_END}/api/Berth/BerthNames`
            : `${Constants.BACK_END}/api/Berth/${userPortId}`;
        //console.log("from bookingsaddnewmodal, url: ", url)
        //console.log("from bookingsaddnewmodal, userRole: ", userRole)
        
        const fetchBerthNames = async () => {
            try {
                const response = await axios.get(url, {
                    headers: { Authorization: `Bearer ${token}` }
                });
                setBerthNames(response.data);
            } catch (error) {
                console.error('Error fetching berth names:', error);
                if (checkAuthenticateIssue(error)) {
                    navigate("/Login2");
                }
            }
        };
        fetchBerthNames();
    }, [userPortId, token]);
    
    useEffect(() => {
        const fetchVesselNames = async () => {
            try {
                const response = await axios.get(`${Constants.BACK_END}/api/Vessel`, {
                    headers: { Authorization: `Bearer ${token}` }
                });
                setVesselNames(response.data);
            } catch (error) {
                console.error('Error fetching vessel names:', error);
                if (checkAuthenticateIssue(error)) {
                    navigate("/Login");
                }
            }
        };
        fetchVesselNames();
    }, [token]);
    

    const handleChange = (e) => {
        const { id, value } = e.target;
        setFormData(prevFormData => ({
            ...prevFormData,
            [id]: value.slice(0, 500)
        }));
    };

    const handleNameChange = async (e) => {
        const { id, value } = e.target;
    
        setFormData((prevFormData) => ({
            ...prevFormData,
            [id]: value.slice(0, 500),
        }));
    
        try {
            const response = await axios.get(
                `${Constants.BACK_END}/api/VesselSpecialNote/port/${userPortId}/vesselId/${value}`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        "Content-Type": "application/json",
                    },
                }
            );
            if (response.data && response.data.length > 0) {
                const note = response.data[0].vesselNote || ''; 
                setFormData((prevFormData) => ({
                    ...prevFormData,
                    vesselNote: note,
                }));
            }
        } catch (error) {
            console.error("Error fetching vessel note:", error);
            if (checkAuthenticateIssue(error)) {
                navigate("/Login");
            }
        }
    };
    

    useEffect(() => {
    console.log("formData: ", formData)
    },[formData]);

    const handleDateChange = (date, id) => {
        const localDate = date ? moment.utc(date).format() : '';
        setFormData(prevFormData => {
            const newFormData = { ...prevFormData, [id]: localDate };

            if (newFormData.mooringDateTime && newFormData.departureDateTime) {
                setBookingDateError(moment(newFormData.mooringDateTime).isSameOrAfter(moment(newFormData.departureDateTime)));
            }
            return newFormData;
        });
    };

    const getCoordinatesByBerth = (berthName) => {
        if (berthByFactor[berthName]) {
            const [xCoordinateFactor, yCoordinateFactor] = berthByFactor[berthName]
            return {
                xCoordinateFactor: xCoordinateFactor.xCoordinateFactor,
                yCoordinateFactor: yCoordinateFactor.yCoordinateFactor
            };
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!["PortAdmin"].includes(userRole)) {
            toast.error('You do not have the necessary permissions to add this booking.', {
                position: "top-center",
                hideProgressBar: true,
                autoClose: 3000
            });
            return;
        }
        if (validateForm()) {
            const { xCoordinateFactor, yCoordinateFactor } = getCoordinatesByBerth(formData.berthName);
            const updatedFormData = { 
                ...formData, 
                portId: userPortId, 
                xCoordinateFactor, 
                yCoordinateFactor,
                passengers: formData.passengers === '' ?  null : +formData.passengers,
                crew: formData.crew === '' ?  null : +formData.crew
            };
           try{
            const response = await axios.post(`${Constants.BACK_END}/api/Booking`, updatedFormData, { headers: { Authorization: `Bearer ${token}` } });
            toast.success('Booking added successfully', {
                position: "top-center",
                hideProgressBar: true,
                autoClose: 1500
            });
            setTimeout(() => {
                closeModal();
            }, 1500);
            fetchBookingListThisYear();
           } catch (error) {
            let errorMessage = 'An unknown error occurred.';
            if (error.response) {
                errorMessage = error.response.data || 'An error occurred while adding the booking.';
            } else if (error.request) {
                errorMessage = 'No response received from the server.';
            } else {
                errorMessage = error.message;
            }
            toast.error(`Error adding booking: ${errorMessage}`, {
                position: "top-center",
                hideProgressBar: true,
                autoClose: 3000
            });
            console.error('Error adding booking:', error);
           }
        }
    };

    const validateForm = () => {
        const newErrors = {};

        if (!formData.vesselId) { newErrors.vesselId = 'Vessel Name is required'; }
        if (!formData.mooringDateTime) { newErrors.mooringDateTime = 'Mooring Date and Time is required'; }
        if (!formData.departureDateTime) { newErrors.departureDateTime = 'Departure Date and Time is required'; }
        if (!formData.contactDateTime) { newErrors.contactDateTime = 'Contact Date and Time is required'; }
        if (!formData.berthName) { newErrors.berthName = 'Berth Name is required'; }
        if (!formData.status) { newErrors.status = 'Status is required'; }
        if (!formData.vesselOrientation) { newErrors.vesselOrientation = 'Orientation is required'; }

        if (bookingDateError) {
            console.log('error found in times');
            newErrors.departureDateTime2 = 'Departure Date and Time must be after Mooring Date and Time';
            console.log(newErrors.departureDateTime2);
        } else {
            console.log('error NOT found in times');
            console.log(newErrors.departureDateTime2);
        }
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    return (
        <div className="modal-container" >             
            <ToastContainer />
            <form ref={modalRef} className="row g-3" id="bookingsUpdateModal" onSubmit={handleSubmit}>
            
                {bookingDateError && (
                    <div className="col-md-12">
                        <div className="alert alert-danger" role="alert">
                            Mooring date and time must be before departure date and time.
                        </div>
                    </div>
                )}
                <div className="col-md-6">
                    <label className="modalLables" htmlFor="agent">Agent</label>
                    <input
                        type="text"
                        className="form-control"
                        id="agent"
                        placeholder="Agent"
                        value={formData.agent}
                        onChange={handleChange}
                    />
                </div>

                <div className="col-md-6">
                    <label className="modalLables" htmlFor="contactDateTime">Contact Date <span className="required">*</span></label>
                    <DateTime
                        className={`form-control ${errors.contactDateTime ? 'is-invalid' : ''}`}
                        value={formData.contactDateTime ? moment(formData.contactDateTime) : ''}
                        onChange={date => handleDateChange(date, "contactDateTime")}
                    />
                    {errors.contactDateTime && <div className="invalid-feedback">{errors.contactDateTime}</div>}
                </div>

                <div className="col-md-6">
                    <label className="modalLables" htmlFor="vesselId">Vessel Name<span className="required">*</span></label>
                    <select
                        className={`form-control ${errors.vesselId ? 'is-invalid' : ''}`}
                        id="vesselId"
                        value={formData.vesselId}
                        onChange={handleNameChange}
                    >
                        <option value="">Select Vessel</option>
                        {vesselNames.map((vessel, index) => (
                            <option key={index} value={vessel.id}>
                                {vessel.name}
                            </option>
                        ))}
                    </select>
                    {errors.vesselId && <div className="invalid-feedback">{errors.vesselId}</div>}
                </div>

                <div className="col-md-6">
                    <label className="modalLables" htmlFor="berthName">Berth Name <span className="required">*</span></label>
                    <select
                        className={`form-control ${errors.berthName ? 'is-invalid' : ''}`}
                        id="berthName"
                        value={formData.berthName}
                        onChange={handleChange}
                    >
                        <option value="">Select Berth</option>
                        {berthNames.map((name, index) => (
                            <option key={index} value={name}>
                                {name}
                            </option>
                        ))}
                    </select>
                    {errors.berthName && <div className="invalid-feedback">{errors.berthName}</div>}
                </div>

                <div className="col-md-6">
                    <label className="modalLables" htmlFor="vesselOrientation">Orientation <span className="required">*</span></label>
                    <select
                        className={`form-control ${errors.vesselOrientation ? 'is-invalid' : ''}`}
                        id="vesselOrientation"
                        value={formData.vesselOrientation}
                        onChange={handleChange}
                    >
                        <option value="">Select Orientation</option>
                        <option value="PORT">PORT</option>
                        <option value="STARBOARD">STARBOARD</option>
                    </select>
                    {errors.status && <div className="invalid-feedback">{errors.status}</div>}
                </div>

                <div className="col-md-6">
                    <label className="modalLables" htmlFor="status">Status <span className="required">*</span></label>
                    <select
                        className={`form-control ${errors.status ? 'is-invalid' : ''}`}
                        id="status"
                        value={formData.status}
                        onChange={handleChange}
                    >
                        <option value="">Select Status</option>
                        <option>TENTATIVE</option>
                        <option>CONFIRMED</option>
                    </select>
                    {errors.status && <div className="invalid-feedback">{errors.status}</div>}
                </div>

                <div className="col-md-6">
                    <label className="modalLables" htmlFor="mooringDateTime">Mooring Date and Time <span className="required">*</span></label>
                    <DateTime
                        isValidDate={valid}
                        className={`form-control ${errors.mooringDateTime ? 'is-invalid' : ''}`}
                        value={formData.mooringDateTime ? moment(formData.mooringDateTime) : ''}
                        onChange={date => handleDateChange(date, "mooringDateTime")}
                    />
                    {errors.mooringDateTime && <div className="invalid-feedback">{errors.mooringDateTime}</div>}
                </div>

                <div className="col-md-6">
                    <label className="modalLables" htmlFor="departureDateTime">Departure Date and Time <span className="required">*</span></label>

                    <DateTime
                        className={`form-control ${errors.departureDateTime ? 'is-invalid' : ''}`}
                        value={formData.departureDateTime ? moment(formData.departureDateTime) : ''}
                        onChange={date => handleDateChange(date, "departureDateTime")}
                    />
                    {errors.departureDateTime && <div className="invalid-feedback">{errors.departureDateTime}</div>}
                    {errors.departureDateTime2 && <div className="invalid-feedback">{errors.departureDateTime2}</div>}
                </div>
                <div className="col-md-6">
                    <label className="modalLables" htmlFor="agent">Passengers</label>
                    <input
                        type="number"
                        className="form-control"
                        id="passengers"
                        placeholder="passengers"
                        min="0"
                        step="1"
                        value={formData.passengers}
                        onChange={handleChange}
                        onInput={(e) => {
                            e.target.value = e.target.value.replace(/[^\d]/g, '');}}
                    />
                </div>
                <div className="col-md-6">
                    <label className="modalLables" htmlFor="agent">Crew</label>
                    <input
                        type="number"
                        className="form-control"
                        id="crew"
                        placeholder="crew"
                        min="0"
                        step="1"
                        value={formData.crew}
                        onChange={handleChange}
                        onInput={(e) => {
                            e.target.value = e.target.value.replace(/[^\d]/g, '');}}
                    />
                </div>
                <div className="col-md-6">
                    <label className="modalLables" htmlFor="specialNote">Booking Note</label>
                    <textarea
                        type="text"
                        className="form-control"
                        id="specialNote"
                        placeholder="Booking Note"
                        maxLength={500}
                        value={formData.specialNote}
                        onChange={handleChange}
                        
                    />
                </div>
                <div className="col-md-6">
                    <label className="modalLables" htmlFor="specialNote">Vessel Note</label>
                    <textarea
                        type="text"
                        className="form-control"
                        id="vesselNote"
                        placeholder="Vessel Note"
                        maxLength={500}
                        value={formData.vesselNote}
                        onChange={handleChange}
                    />
                </div>
                <div className="addButtons">
                <button type="submit" className="btn addingBooking">Add New Booking</button>
                <button type="button" className="btn cancelBooking" onClick={closeModal}>Cancel</button>
                </div>
            </form>
        </div>
    );
}

export default AddNewBookingModal;
