import { useEffect } from 'react';
import msalInstance from '../Config/MsalConfig';
import { useUserRoleContext } from './useUserRoleContext';


const useRefreshToken = () => {

  const { setUserRole } = useUserRoleContext();

    useEffect(() => {
        const account = msalInstance.getAllAccounts()[0];
        
        if (!account) {
            console.log("No account found. User may not be logged in.");
            return;
        }

        msalInstance.acquireTokenSilent({
            scopes: ["api://d7c2bf62-20bf-4852-8082-a615dd4aa053/App.Read"],
            account: account,
        }).then(response => {
            const { accessToken, refreshToken } = response;

            localStorage.setItem("jwtToken", accessToken);
            sessionStorage.setItem("jwtToken", accessToken);

            sessionStorage.setItem("jwtRefreshToken", refreshToken || "");

            console.log("Token refreshed successfully",accessToken );

            const idTokenClaims = account.idTokenClaims;

            if (idTokenClaims?.roles) {
                setUserRole(idTokenClaims.roles[0]);
                console.log("User role refreshed successfully:", idTokenClaims.roles[0]);
            } else {
                setUserRole("User");
                console.log("Default user role set: User");
            }

        }).catch(error => {
            console.error('Error acquiring token silently:', error);
        });
    }, []); 
};

export default useRefreshToken;
