import React, { useEffect, useMemo, useState } from "react";
import ReactApexChart from "react-apexcharts";
import OperationsMapShowGangwayReservationModal from './OperationsMapShowGangwayReservationModal';
import  ColorMaps  from "../../utils/ColorMaps";

function OperationsMapGangwayReservations({ bookingResources }) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedReservation, setSelectedReservation] = useState({});

  // useEffect(() => {
  //   if (Array.isArray(bookingResources)) {
  //     bookingResources.forEach((reservation) => {
  //       console.log("bookingResources:", reservation);
  //     });
  //   } else {
  //     console.log("bookingResources is not an array");
  //   }
  // }, [bookingResources]);
  // useEffect(() => {
  //   console.log("Render triggered by booking resources change:", bookingResources);
  // }, [bookingResources]);

  const getColor = (gangwayId) => ColorMaps.colorMapIdDark[gangwayId] || "#000000"; 
  
  const series = useMemo(() => [
    {
      name: "Gangways",
      data: bookingResources.length > 0 ? bookingResources.map((reservation, index) => {
        return {
          x: reservation.gangwayName,
          y: [
            new Date(reservation.startDateTime).getTime(),
            new Date(reservation.endDateTime).getTime()
          ],
          fillColor: getColor(reservation.gangwayId),
          id: reservation.id,
          bookingId: reservation.bookingId,
          vesselName: reservation.vesselName,
          vesselDoorId: reservation.vesselDoorId,
          gangwayId: reservation.gangwayId
        };
      }) : [],
    },
  ], [bookingResources]);

  const options = 
    {
      chart: {
        toolbar: {
          show: true,
          tools: {
            download: false,
            selection: false,
            zoom: false,
            zoomin: false,
            zoomout: false,
            pan: true,
            reset: true,
          },
          autoSelected: 'pan',
        },
        events: {
          dataPointSelection: (event, chartContext, { seriesIndex, dataPointIndex, w }) => {
            try {
              const selectedReservation = w.globals.initialSeries[seriesIndex]?.data[dataPointIndex];
             // console.log("selectedReservation: ", selectedReservation);
              setTimeout(() => {
                setSelectedReservation(selectedReservation);
                setIsModalOpen(true);
              }, 0);
            } catch (error) {
              console.error("Error selecting data point: ", error);
            }
          },
        },
      },
      title: {
        text: 'Gangway Reservations',
        align: 'center',
        style: {
          fontSize: '16px',
          fontWeight: 'bold',
          fontFamily: 'Montserrat',
          color: '#22418a',
        },
      },
      tooltip: {
        custom: function ({ series, seriesIndex, dataPointIndex, w }) {
          const dataPoint = w.globals.initialSeries[seriesIndex]?.data[dataPointIndex];
          const startTime = dataPoint ? new Date(dataPoint.y[0]).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }) : "No data";
          const endTime = dataPoint ? new Date(dataPoint.y[1]).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }) : "No data";
          const vesselName = dataPoint?.vesselName || "No vessel";

          return '<div class="arrow_box">' +
            '<span style="font-family: Montserrat, sans-serif;">' + vesselName + '<span><br/>'+
           // '<span>' + (dataPoint?.x || "No data") + '</span><br/>' +
            '<span>Start: ' + startTime + '</span><br/>' +
            '<span>End: ' + endTime + '</span>' +
            '</div>';
        },
      },
      plotOptions: {
        bar: {
          horizontal: true,
          barHeight: '50%',
          dataLabels: {
            position: 'center',
            enabled: true,
            formatter: function (val, opts) {
              return opts.w.globals.seriesNames[opts.seriesIndex] + ': ' + (opts.w.globals.series[opts.seriesIndex][opts.dataPointIndex]?.x || "No data");
            },
          },
        },
      },
      xaxis: {
        type: 'datetime',
        tickAmount: 6,
        labels: {
          show: bookingResources.length > 0,
          style: {
            fontSize: '12px',
            fontFamily: 'Montserrat',
            fontWeight: 400,
            colors: ['#22418a'],
            show: bookingResources.length > 0,
          },
          formatter: function (val) {
            return new Date(val).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit'});
          },
        },
        axisBorder: {
          show: bookingResources.length > 0,
          color: '#22418a',
          height: 1,
        },
        axisTicks: {
          show: bookingResources.length > 0,
          color: '#22418a',
          //width: 5,
        },
        show: bookingResources.length > 0,
      },
      yaxis: {
        labels: {
          style: {
            fontSize: '14px',
            fontFamily: 'Montserrat',
            fontWeight: 600,
            colors: ['#22418a'],
          },
        },
        axisBorder: {
          show: bookingResources.length > 0,
          color: '#22418a',
          width: 1,
        },
        axisTicks: {
          show: bookingResources.length > 0,
          color: '#22418a',
          //width: 5,
        },
        show: bookingResources.length > 0,
      },
      grid: {
        show: true,
        borderColor: '#22418a',
        strokeDashArray: 1,
        position: 'back',
        xaxis: {
          lines: {
            show: false,
          },
        },
        yaxis: {
          lines: {
            show: bookingResources.length > 0,
          },
        },
      },
    };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedReservation(null);
  };

  return (
    <div className="operationsMapGangwayReservationsContainer">
      {bookingResources.length === 0 ? (
        <div style={{ textAlign: 'center', fontSize: '14px', color: '#22418a' }}>
          No reservations for this day
        </div>
      ) : (
        <div id="chart">
          <ReactApexChart options={options} series={series} type="rangeBar" height={150} width={330}/>
        </div>
      )}
      {isModalOpen &&
        <OperationsMapShowGangwayReservationModal
        selectedReservation={selectedReservation}
          closeModal={handleCloseModal} />
      }
    </div>
  );
}

export default OperationsMapGangwayReservations;
