import React from 'react';
import Constants from '../../Constants';
import { FaEdit, FaTrash } from "react-icons/fa";

const formattedDateTime = (dateTimeString) => {
    return new Date(dateTimeString).toLocaleString(undefined, { dateStyle: 'long', timeStyle: 'short' });
  };

export const Columns = ({ }) => [

  {
    Header: 'Vessel Name',
    accessor: 'vesselName',
    // Cell: ({ cell: { value } }) => (
    //   <a href={Constants.FRONT_END + `Vessels`}>
    //     {value}
    //   </a>
    // ),
  },
  {
    Header: 'Mooring Date and Time',
    accessor: 'mooringDateTime',
    Cell: ({ cell: { value } }) => formattedDateTime(value),
  },
  {
    Header: 'Departure Date and Time',
    accessor: 'departureDateTime',
    Cell: ({ cell: { value } }) => formattedDateTime(value),
  },
  {
    Header: 'Status',
    accessor: 'status',
    Cell: ({ cell: { value } }) => (
        <span className={`badge badge-pill badge-${value === 'CONFIRMED' ? 'success' : value === 'TENTATIVE' ? 'warning' : 'danger'}`}>
          {value}
        </span>
      ),
  },
  // {
  //   Header: 'Cancellation Reason',
  //   accessor: 'cancellationReason',
  // },
  {
    Header: 'Booking note',
    accessor: 'specialNote',
  },
  {
    Header: 'Contact Date',
    accessor: 'contactDateTime',
    Cell: ({ cell: { value } }) => formattedDateTime(value),
  },
  {
    Header: 'Agent',
    accessor: 'agent',
  },
  {
    Header: 'Berth Name',
    accessor: 'berthName',
  },
  {
    Header: 'Passengers',
    accessor: 'passengers'
  },
  {
    Header: 'Crew',
    accessor: 'crew'
  },
  {
    Header: 'Vessel Note',
    accessor: 'vesselNote',
  }


];


