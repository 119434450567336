import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useUserRoleContext } from '../../Contexts/useUserRoleContext';
import msalInstance from '../../Config/MsalConfig';
import axios from 'axios';
import Constants from '../../Constants';

function Login({ setAuthenticated }) {
  const { setUserRole } = useUserRoleContext();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const images = [
    '/images/LandingPage5.jpg',
    '/images/LandingPage2.jpg',
    '/images/LandingPage3.jpg',
  ];

  const handleLogin = async () => {
    setLoading(true);
    try {
      const loginResponse = await msalInstance.loginPopup({
        scopes: ["api://d7c2bf62-20bf-4852-8082-a615dd4aa053/App.Read"],
      });

      const { accessToken } = loginResponse;
      console.log("from login.js, loginResponse: ", loginResponse)
      localStorage.setItem("jwtToken", accessToken);
      sessionStorage.setItem("jwtToken", accessToken);

      if (!loginResponse.idTokenClaims.roles) {
        setUserRole("User")
      } else { setUserRole(loginResponse.idTokenClaims.roles[0]); }

      if (loginResponse.idTokenClaims && loginResponse.idTokenClaims.groups) {
        const objectIds = loginResponse.idTokenClaims.groups;
        console.log("Login objectIds: ", objectIds)
        for (let objectId of objectIds) {
          const foundPort = await getPortId(objectId);
          if (foundPort) break;
        }
      }
      setAuthenticated(true);
      navigate('/operationsMap');
    } catch (error) {
      console.error('Login failed:', error);
      toast.error('Login Failed. Please try again.', { position: "top-center" });
    } finally {
      setLoading(false);
    }
  };

  const getPortId = async (objectId) => {
    const token = sessionStorage.getItem("jwtToken") || localStorage.getItem("jwtToken");

    try {
      const response = await axios.get(`${Constants.BACK_END}/api/Port/portObjectId/${objectId}`, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });
      if (response.data && response.data.id) {
        console.log('Port ID found: ', response.data.id);
        sessionStorage.setItem("userPortId", response.data.id);
        console.log("login, portid: ", response.data.id)
        return true;
      } else {
        console.log(`No port found for objectId: ${objectId}`);
        return false;
      }
    } catch (error) {
      console.error('Error fetching port ID:', error);
      return false;
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) => 
        prevIndex === images.length - 1 ? 0 : prevIndex + 1
      );
    }, 4000);

    return () => clearInterval(interval);
  }, [images.length]);

  return (
    <div className='login2Container1'>
      <ToastContainer />
      <div className='login2Container2'>
        <h1 className='login2Heading'>PortCon</h1>
        <div className='buttonGroup2'>
          <button className='btn menuButton2'>Demo</button>
          <button className='btn menuButton2'>About</button>
          <button className='btn menuButton2'>Contact Us</button>
        </div>
      </div>

      <div className='login2Container3'>
        {images.map((image, index) => (
          <img
            key={index}
            src={image}
            alt="Slideshow"
            className={currentImageIndex === index ? 'active' : ''}
          />
        ))}


          <button
          onClick={handleLogin}
          className="btn login2Button"
          disabled={loading}
        >
          {loading ? 'Logging in...' : 'Sign In'}
        </button>
</div>
    </div>
  );
}

export default Login;
