import 'bootstrap/dist/css/bootstrap.css';
import React, { useState, useEffect, useContext, useRef } from 'react';
import Constants from '../../Constants';
import "react-datetime/css/react-datetime.css";
import 'moment-timezone';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FaTrash, FaSave, FaCalendarCheck, FaCalendarPlus } from "react-icons/fa";
import { ComposedChart, Bar, BarChart, Cell, LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, ReferenceLine } from "recharts";
import useOperationsPictureBookingResources from './useOperationsMapBookingResources';
import { CustomTooltip } from "./OperationsMapCustomToolTip";
import { getColorBasedOnValue } from "./OperationsMapGetColorUtil";
import { FaFolderPlus } from "react-icons/fa";
import html2canvas from 'html2canvas';
import { useReportImagesContext } from '../../Contexts/useReportImagesContext';
import OperationsMapAddGangwayReservationForm from './OperationsMapAddGangwayReservationForm';
import { Tooltip as ReactToolTip} from 'react-tooltip' // had to use alias because Tooltip was already defined in recharts

function OperationsMapGraphModal(
    {
        closeModal,
        vesselDoorId,
        vesselDoorName,
        booking,
        doorSlopeData,
        doorGangwaySelection,
        gangwayId,
        gangwayGroupName }) {

    const modalRef = useRef(null);
    const token = sessionStorage.getItem("jwtToken") || localStorage.getItem("jwtToken");
    const userPortId = sessionStorage.getItem("userPortId") || localStorage.getItem("userPortId");
    const {
        bookingResources,
        handleRemoveBookingResource,
        handleSelectBookingResource,
        handleUpdateBookingResource,
        fetchBookingResources,
        hasBookingResource
    } = useOperationsPictureBookingResources(token)

    const [showGangwayReservation, setShowGangwayReservation] = useState(false);
    

    const formattedTime = (value) => {
        const date = new Date(value);
        const utcDate = new Date(date.toISOString());
        return utcDate;
      };
      const convertToUTCWithOffset = (value) => {
        const date = new Date(value);
    
        // Extract the components in UTC
        const year = date.getUTCFullYear();
        const month = String(date.getUTCMonth() + 1).padStart(2, '0');
        const day = String(date.getUTCDate()).padStart(2, '0');
        const hours = String(date.getUTCHours()).padStart(2, '0');
        const minutes = String(date.getUTCMinutes()).padStart(2, '0');
        const seconds = String(date.getUTCSeconds()).padStart(2, '0');
    
        // Construct the string with explicit +00:00 offset
        return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}+00:00`;
    };
    
      //console.log("Hello from operationsMapGraphModal, mooring datetime: ", booking.mooringDateTime)

      const formattedMooringDateTime =  convertToUTCWithOffset(booking.mooringDateTime)
      //console.log("Hello from operationsMapGraphModal, formatted mooring datetime: ", formattedMooringDateTime)
    
//useEffect ( () =>{
//console.log("doorslopedata: ", doorSlopeData,)
// }, [doorSlopeData])


      useEffect(() => {
        let handler = (e) => {
            if (modalRef.current && !modalRef.current.contains(e.target)) {
                closeModal()
            }
        };
        document.addEventListener("mousedown", handler)
        return () => {
            document.removeEventListener('mousedown', handler);
        };
    }, [closeModal]);

    const { reportImages, setReportImages } = useReportImagesContext();
    const handleAddToReports = (bookingId) => {
        const container = document.getElementById(`capture-container4-${bookingId}`);

        html2canvas(container, {
            scale: 1,
            useCORS: true,
        }).then(canvas => {
            //console.log(canvas);
            const dataURL = canvas.toDataURL();
            setReportImages(prevImages => [...prevImages, dataURL]);
        });
    }

    const isResourceFound = bookingResources.some(resource =>
        resource.bookingId === booking.bookingId && resource.vesselDoorName === vesselDoorId && resource.gangwayId === gangwayId);
    const foundResource = bookingResources.find(resource =>
        resource.bookingId === booking.bookingId && resource.vesselDoorName === vesselDoorId && resource.gangwayId === gangwayId);

    return (
        <div className={`operationsListModal${isResourceFound ? 'Border' : ''}`}>
            {/* <ToastContainer /> */}
            <div className="pictureGraphModalContainer"  >
                <div className="operationsGraphModal" ref={modalRef} id={`capture-container4-${booking.id}`}>
                    <p>Door Name: {vesselDoorName}, Gangway Type: {gangwayGroupName}</p>
                    <button 
                            data-html2canvas-ignore 
                            type="button" 
                            className="graphModalCancel2" 
                            onClick={closeModal}>
                                <span>&times;</span>
                            </button>
                    <div className="chartContainer" >

                        <ResponsiveContainer>
                            <ComposedChart
                                width={1200}
                                height={225}
                                data={doorSlopeData}
                                margin={{
                                    top: 10, right: 30, left: 0, bottom: 0,
                                }}
                                barCategoryGap={0}
                                barGap={0}
                            >
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="date" tickFormatter={(tick) => new Date(tick).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false })} />
                                <YAxis domain={[-40, 40]} allowDataOverflow={true} />
                                <Tooltip cursor={false} content={<CustomTooltip />} />
                                <Bar
                                    dataKey="slopeMultiples"
                                    fill={(entry) => getColorBasedOnValue(entry.slope)}
                                    label={{ position: 'top' }}
                                    barSize={40}
                                >
                                    {doorSlopeData?.map((entry, index) => (
                                        <Cell
                                            key={`cell-${index}`}
                                            fill={getColorBasedOnValue(entry.slope)}
                                            stroke={getColorBasedOnValue(entry.slope)}
                                            strokeWidth={10}
                                        />
                                    ))}
                                </Bar>
                                <ReferenceLine x={booking.mooringDateTime} stroke="green" label="Mooring Time" />
                                <ReferenceLine x={booking.departureDateTime} stroke="red" label="Departure Time" />
                                <Line
                                    type="monotone"
                                    dataKey="slope"
                                    stroke="#8884d8"
                                    dot={false}
                                    strokeWidth={2}
                                />
                            </ComposedChart>
                        </ResponsiveContainer>
                        <div className="iconContainer">

                            {/* <button 
                            data-html2canvas-ignore 
                            type="button" 
                            className="graphModalCancel" 
                            onClick={closeModal}>
                                <span>&times;</span> 
                            </button>*/}
                            <button 
                            data-tooltip-id="operationsMapGraphModalIcons"
                            data-tooltip-content="Add Graph to Reports"
                            data-html2canvas-ignore 
                            className="graphModalAddToReports" 
                            onClick={() => handleAddToReports(booking.id)}><FaFolderPlus className="addToReportsIcon" /></button>
                            {isResourceFound ? (
                                <>
                                    <button 
                                    data-tooltip-id="operationsMapGraphModalIcons"
                                    data-tooltip-content="Remove Gangway Reservation"
                                    data-html2canvas-ignore 
                                    className="btn delete" 
                                    onClick={() => {
                                        handleRemoveBookingResource(booking.bookingId, vesselDoorId, foundResource?.gangwayId)
                                        setShowGangwayReservation(false)
                                    }}>
                                        <FaTrash className='deleteIcon' />
                                    </button>
                                </>
                            ) : (
                                <button 
                                data-tooltip-id="operationsMapGraphModalIcons"
                                data-tooltip-content="Add Gangway Reservation"
                                data-html2canvas-ignore className="btn save"
                                    onClick={
                                        () => {
                                            // handleSelectBookingResource(booking.bookingId, vesselDoor, gangwayId, booking.berthId)
                                            setShowGangwayReservation(true)
                                        }}>
                                    <FaCalendarPlus className='deleteIcon' />
                                </button>
                            )}
                            <ReactToolTip
                            id="operationsMapGraphModalIcons"
                            place="right"
                            className="operationsMapGraphModalTooltip"
                            />
                        </div>
                                            
                    </div>
                    {showGangwayReservation ? (
                        <OperationsMapAddGangwayReservationForm
                            closeModal={closeModal}
                            bookingId={booking.bookingId}
                            gangwayGroupName={gangwayGroupName}
                            vesselDoorId={vesselDoorId}
                            mooringDateTime={booking.mooringDateTime}
                            departureDateTime={booking.departureDateTime}
                            berthName={booking.berth}
                        />) : (<div />)}
                </div>
            </div>
        </div>
    )
}

export default OperationsMapGraphModal;