import { Ellipse } from 'react-konva';
import Constants from '../../Constants';
import axios from 'axios';
import ColorMaps  from "../../utils/ColorMaps";



const handleEllipseClick = (index, door, vesselId, setSelectedDoor, setSelectedEllipseIndex) => {
  setSelectedEllipseIndex(prevState => ({
    ...prevState,
    [vesselId]: index,
  }));
  setSelectedDoor(door);
  // console.log(door)
};

const handlePowerEllipseClick = (index, door, vesselId, setSelectedDoor, setSelectedPowerEllipseIndex) => {
  setSelectedPowerEllipseIndex(prevState => ({
    ...prevState,
    [vesselId]: index,
  }));
  setSelectedDoor(door);
  // console.log(door)
};

const fetchSlopeData = (mooringDateTime, departureDateTime, doorId, vesselSide, berthId, setDoorSlopeDataGangwayGroups) => {

  const toUtcZFormat = (dateString) => {
    const date = new Date(dateString);
    //console.log("date: !", date.toISOString()) // Parse the date
    return date.toISOString(); // Format as ISO 8601 and ensure 'Z' without milliseconds
  };

  const adjustedMooringDateTime = toUtcZFormat(mooringDateTime)
  const adjustedDepartureDateTime = toUtcZFormat(departureDateTime)
  const token = sessionStorage.getItem("jwtToken") || localStorage.getItem("jwtToken");
  const userPortId = sessionStorage.getItem("userPortId") || localStorage.getItem("userPortId");

  const fetchGangwayGroups = () => {
    const apiUrlGetGangwayGroups = `${Constants.BACK_END}/api/Gangway/groups/portId/${userPortId}`;
    
    axios.get(apiUrlGetGangwayGroups, {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    })
    .then(response => {
      //console.log("Hello from OMRD, response: ", response);
      response.data.forEach(gangway => {
        fetchSlopeForEachGangway(gangway, setDoorSlopeDataGangwayGroups);
      });
    })
    .catch(error => {
      console.error("Error fetching gangway groups:", error);
    });
  };

  const fetchSlopeForEachGangway = (gangway, setSlopeDataFunction) =>{
    //console.log("GangwayId: ", gangway.id)
    const apiUrlGetSlope = `${Constants.BACK_END}/api/Calculations/slopes/gangwayId/${gangway.id}/mooringDateTime/${adjustedMooringDateTime}/departureDateTime/${adjustedDepartureDateTime}/doorId/${doorId}/vesselSide/${vesselSide}/berthId/${berthId}`;
    axios.get(apiUrlGetSlope, {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    })
      .then(response => {
        //console.log(response)
        const processedData = response.data.map(item => ({
          ...item,
          slopeMultiples: [(item.slope + 1) * 10000, (item.slope + 1) * -10000]
        }));
        setSlopeDataFunction(prevState => ({
          ...prevState,
          [gangway.groupName]:{
            ...(prevState[gangway.groupName] || {}),
          [doorId]: processedData}
        }));
      })
      .catch(error => {
        console.error('Error fetching slopes:', error);
        setSlopeDataFunction(prevState => ({
          ...prevState,
          [gangway.groupName]:{
            ...(prevState[gangway.groupName] || {}),
          [doorId]: []
          }
        }));
      });
  }

  fetchGangwayGroups();
}

const fetchDifferenceFromDockData = (mooringDateTime, departureDateTime, doorId, vesselSide, berthId, setPowerDoorData) => {

  const toUtcZFormat = (dateString) => {
    const date = new Date(dateString);
    //console.log("date: ", date.toISOString()) // Parse the date
    return date.toISOString(); // Format as ISO 8601 and ensure 'Z' without milliseconds
  };

  const adjustedMooringDateTime = toUtcZFormat(mooringDateTime)
  const adjustedDepartureDateTime = toUtcZFormat(departureDateTime)

  const token = sessionStorage.getItem("jwtToken") || localStorage.getItem("jwtToken");
  const apiUrlGetDifferences = `${Constants.BACK_END}/api/Calculations/differences/mooringDateTime/${adjustedMooringDateTime}/departureDateTime/${adjustedDepartureDateTime}/doorId/${doorId}/vesselSide/${vesselSide}/berthId/${berthId}`;
  axios.get(apiUrlGetDifferences, {
    headers: {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json'
    }
  })
    .then(response => {
      setPowerDoorData(prevState => ({
        ...prevState,
        [doorId]: response
      }));
    })
    .catch(error => {
      console.error('Error fetching differences:', error);
      setPowerDoorData(prevState => ({
        ...prevState,
        [doorId]: []
      }));
    });
}


export const renderDoors = (vesselData,
  doors,
  scale,
  vesselOrientation,
  vesselId,
  setSelectedDoor,
  setSelectedEllipseIndex,
  selectedEllipseIndex,
  bookingResources,
  setSelectedVesselId,
  setShowGraphsFlag,
  setDoorSlopeGangwayGroups,
  setDoorSlopeDataGangwayGroups) => {
  const vessel = vesselData.find(v => v.vesselId === vesselId);
  if (!vessel) {
    console.error(`Vessel with ID ${vesselId} not found in vesselData.`);
    return null;
  }


  const getColorByGangwayId = (doorId) => {
    const resource = bookingResources.find(resource => resource.vesselDoorId === doorId);
    return resource ? ColorMaps.colorMapIdLight[resource.gangwayId] || "transparent" : "transparent";
  };

  const filteredDoors = doors.filter(door => door.orientation === vesselOrientation);
  return filteredDoors.map((door, index) => (

    <Ellipse
      key={index}
      x={scale * door.x}
      y={scale * door.y}
      width={door.width * scale}
      height={door.height * scale}
      fill={
        selectedEllipseIndex[vesselId] === index ? "rgba(255, 255, 0, 0.5)" : getColorByGangwayId(door.id)
      }
      onClick={(e) => {
        setSelectedEllipseIndex({});
        handleEllipseClick(index, door, vesselId, setSelectedDoor, setSelectedEllipseIndex);
        const stage = e.target.getStage();
        fetchSlopeData(
          vessel.mooringDateTime,
          vessel.departureDateTime,
          door.id,
          door.orientation,
          vessel.berthId,
          setDoorSlopeDataGangwayGroups
        );
        setSelectedVesselId(vessel.vesselId);
        setShowGraphsFlag(true);
      }}
    />
  ));
};


export const renderPowerDoors = (vesselData,
  powerDoors,
  scale,
  vesselOrientation,
  vesselId,
  setSelectedDoor,
  setSelectedPowerEllipseIndex,
  selectedPowerEllipseIndex,
  bookingResources,
  setSelectedVesselId,
  setShowPowerGraphFlag,
  setPowerDoorData) => {
  const vessel = vesselData.find(v => v.vesselId === vesselId);
  if (!vessel) {
    console.error(`Vessel with ID ${vesselId} not found in vesselData.`);
    return null;
  }

  const getColorByGangwayId = (doorId) => {
    const resource = bookingResources.find(resource => resource.vesselDoorId === doorId);
    return resource ? ColorMaps.colorMapId[resource.gangwayId] || "transparent" : "transparent";
  };

  const filteredDoors = powerDoors.filter(door => door.orientation === vesselOrientation);
  //console.log("powerDoors", powerDoors);
  return filteredDoors.map((door, index) => (
    <Ellipse
      key={index}
      x={scale * door.x}
      y={scale * door.y}
      width={door.width * scale}
      height={door.height * scale}
      fill={
        selectedPowerEllipseIndex[vesselId] === index
          ? "rgba(255, 255, 0, 0.5)"
          : getColorByGangwayId(door.id)
      }
      onClick={(e) => {
        setSelectedPowerEllipseIndex({});
        handlePowerEllipseClick(index, door, vesselId, setSelectedDoor, setSelectedPowerEllipseIndex);
        const stage = e.target.getStage();
        fetchDifferenceFromDockData(
          vessel.mooringDateTime,
          vessel.departureDateTime,
          door.id,
          door.orientation,
          vessel.berthId,
          setPowerDoorData
        );
        setSelectedVesselId(vessel.vesselId);
        setShowPowerGraphFlag(true);
      }}
    />
  ));
};

//export default renderDoors;