import React, { useState, useMemo } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";


const BookingsCalendar = ({bookings, hideCancelled}) => {

    const localizer = momentLocalizer(moment);
    console.log("Bookings: ", bookings)
    const events = useMemo(() => {
        return bookings
        .filter(booking => !hideCancelled || booking.status !== "CANCELLED")
        .map(booking => ({
          title: booking.vesselName || "No Title",
          start: new Date(booking.mooringDateTime), 
          end: new Date(booking.departureDateTime), 
          status: booking.status, 
          bookingId: booking.id,
        }));
    }, [bookings, hideCancelled]);


    const components = {
        event: (props) => {
            const status = props?.event?.status
            switch (status) {
                case 'TENTATIVE':
                    return <div style={{ background: '#fdc305', color: 'black', borderRadius: '5px', paddingLeft: '5px', fontWeight: '500'}}>{props.title}</div>
                case 'CONFIRMED':
                    return <div style={{ background: '#2ca745', color: 'WHITE', borderRadius: '5px', paddingLeft: '5px', fontWeight: '500'}}>{props.title}</div>
                case 'CANCELLED':
                    return <div style={{ background: '#dd3249', color: 'WHITE', borderRadius: '5px', paddingLeft: '5px', fontWeight: '500'}}>{props.title}</div>
            }
            return null
        }
    }

  return (
    <div className='calendar-container'>
      <Calendar
        localizer={localizer}
        events={events}
        startAccessor="start"
        endAccessor="end"
        components={components}
        views={["month", "week", "day"]}
      />
    </div>
  )
}

export default BookingsCalendar
