import axios from 'axios';
import Constants from '../Constants';

const ColorMaps = {
  colorMapIdLight: {},
  colorMapIdDark: {},
};

let colorsLight = [
  'rgba(156, 39, 176, 0.5)',  // Purple
  'rgba(67, 249, 255, 0.5)',  // Cyan
  'rgba(34, 65, 138, 0.5)',   // Navy
  'rgba(255, 87, 34, 0.5)',   // Orange
  'rgba(76, 175, 80, 0.5)',   // Green
  'rgba(244, 67, 54, 0.5)',   // Red
  'rgba(33, 150, 243, 0.5)',  // Blue
  'rgba(121, 85, 72, 0.5)',   // Brown
];

let colorsDark = [
  'rgba(156, 39, 176, 1)',
  'rgba(67, 249, 255, 1)',
  'rgba(34, 65, 138, 1)',
  'rgba(255, 87, 34, 1)',
  'rgba(76, 175, 80, 1)',
  'rgba(244, 67, 54, 1)',
  'rgba(33, 150, 243, 1)',
  'rgba(121, 85, 72, 1)',
];

export const initializeColorMaps = async () => {
  const token = sessionStorage.getItem("jwtToken") || localStorage.getItem("jwtToken");
  const userPortId = sessionStorage.getItem("userPortId") || localStorage.getItem("userPortId");
  const url = `${Constants.BACK_END}/api/Gangway/portId/${userPortId}`;

  try {
    const response = await axios.get(url, {
      headers: { Authorization: `Bearer ${token}` },
    });

    const gangwayIds = response.data;

    gangwayIds.forEach((gangway, index) => {
      ColorMaps.colorMapIdLight[gangway.id] = colorsLight[index % colorsLight.length];
      ColorMaps.colorMapIdDark[gangway.id] = colorsDark[index % colorsDark.length];
    });
  } catch (error) {
    console.error("Error initializing color maps:", error);
  }
};

export default ColorMaps;
