import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import { useUserRoleContext } from '../Contexts/useUserRoleContext';
import { FaBookOpen, FaHome, FaShip, FaChartLine, FaMap, FaFolder } from "react-icons/fa";
import { MdLogin, MdLogout } from "react-icons/md";
import { Tooltip } from 'react-tooltip'

const Header = React.memo(({ isAuthenticated, logout }) => {
  const navigate = useNavigate();
  const { userRole, setUserRole } = useUserRoleContext();

  const handleLogout = () => {
    localStorage.removeItem('jwtToken');
    sessionStorage.removeItem('jwtToken');
    localStorage.removeItem('userRole');
    sessionStorage.removeItem('userRole');
    sessionStorage.removeItem('userPortId');
    setUserRole(null);
    logout();
    navigate('/login2');
  };

  const handleLogin = () => {
    navigate('/login2');
  };

  console.log('userRole from header ' + userRole);

  // if (userRole === null) {
  //   // Optionally, render a loading state or nothing while waiting for userRole
  //   console.log("null user role")
  //   return <div></div>;
  // }

  return (
    <nav className="navbar navbar-expand-lg ">
      <div className="container-fluid">
        <button data-tooltip-id="headerIcons"
        data-tooltip-content="Icon will go here"
        data-tooltip-place="bottom-start" 
        className="btn navHome">
          <FaHome className="homeIcon" />
        </button>
        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarNav">
          <ul className="navbar-nav">
            {isAuthenticated ? (
              <>
                <li className="nav-item">
                  <button
                    data-tooltip-id="headerIcons"
                    data-tooltip-content="Bookings Page"
                    className="btn navBookings" onClick={() => navigate('/Bookings')}>
                    <FaBookOpen className="bookingsIcon" />
                  </button>
                </li>
                {userRole === "Admin" &&
                  <li className="nav-item">
                    <button
                      data-tooltip-id="headerIcons"
                      data-tooltip-content="Vessels Page"
                      className="btn navVessels" onClick={() => navigate('/Vessels')}>
                      <FaShip className="vesselsIcon" />
                    </button>
                  </li>
                }
                <li className="nav-item">
                  <button
                    data-tooltip-id="headerIcons"
                    data-tooltip-content="Operations Page"
                    className="btn navOperations" onClick={() => navigate('/Operations')}>
                    <FaChartLine className="operationsIcon" />
                  </button>
                </li>
                <li className="nav-item">
                  <button data-tooltip-id="headerIcons"
                    data-tooltip-content="Operations Map Page"
                    className="btn navOperationsMap" onClick={() => navigate('/operationsMap')}>
                    <FaMap className="operationsMapIcon" />
                  </button>
                </li>
                <li className="nav-item">
                  <button
                    data-tooltip-id="headerIcons"
                    data-tooltip-content="Reports Page"
                    className="btn reports" onClick={() => navigate('/reports')}>
                    <FaFolder className="reportsIcon" />
                  </button>
                </li>
                <li
                  data-tooltip-id="headerIcons"
                  data-tooltip-content="Logout"
                  data-tooltip-place="bottom-end"
                  className="nav-item"><button className="btn logout" type="button" onClick={handleLogout}>
                    <MdLogout className="logoutIcon" />
                  </button></li>
              </>
            ) : (
              <li className="nav-item">
                <button
                  data-tooltip-id="headerIcons"
                  data-tooltip-content="Login"
                  data-tooltip-place="bottom-end"
                  className="btn login" type="button" onClick={handleLogin}>
                  <MdLogin className="loginIcon" />
                </button>
              </li>
            )}
          </ul>
          <Tooltip
            id="headerIcons"
            place="top"
            className="headerTooltip"
          />

        </div>
      </div>
    </nav>
  );
});

export default Header;
