import React, { useState, useEffect, useContext } from "react";
import {
  AreaChart, Label, ReferenceArea, ComposedChart, Bar, BarChart, Cell, LineChart,
  Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, ReferenceLine
} from "recharts";
import 'moment-timezone';
import 'bootstrap/dist/css/bootstrap.css';
import "react-datetime/css/react-datetime.css";
import { CustomTooltip } from "./OperationsMapCustomToolTip";
import { getColorBasedOnValue } from "./OperationsMapGetColorUtil";
import { useVesselDataContext } from "../../Contexts/useVesselDataContext";


const OperationsMapShowGraphs = ({
  vesselId,
  selectedDoor,
  doorSlopeDataGangwayGroups,
  bookingResources,
  handleGraphModalOpen
}) => {

  const { vesselData, setVesselData } = useVesselDataContext();

  const vessel = vesselData.find(v => v.vesselId === vesselId);

  const formattedTime = (value) => {
    const date = new Date(value);
    return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
  }

  if (!vessel) {
    console.error(`Vessel with id ${vesselId} not found in vesselData`, vesselData);
    return null;
  }

  if (vessel.berthId === 4) {
    return null;
  }

  const berthMap = {
    2: "South East",
    3: "South West",
    4: "East",
    5: "Tender"
  };
  const positionMap = {
    2: { x: 1545, y: 350 },
    3: { x: 130, y: 350 },
    4: { x: 1545, y: 100 },
  };
  const berthName = berthMap[vessel.berthId] || "Berth Unknown";
  const position = positionMap[vessel.berthId] || { x: 0, y: 0 };

  return (
    <div
      className={`operationsPictureShowGraphs ${berthName.replace(/\s+/g, '').toLowerCase()}`}
      style={{
        left: position.x,
        top: position.y,
      }}
    >
      <div className="chartTitle"> Berth: {berthName} </div>
      <div className="chartTitle"> Door Name: {selectedDoor.name}, Door Height: {selectedDoor.doorHeight} </div>

      {Object.entries(doorSlopeDataGangwayGroups)
      .sort(([groupA], [groupB]) => groupA.localeCompare(groupB))
      .map(([gangwayGroup, gangwayData]) => {
        const gangwayDataForDoor = gangwayData[selectedDoor.id] || [];
        const isResourceFound = bookingResources.some(resource => 
          resource.bookingId === vessel.bookingId && 
          resource.vesselDoorId === selectedDoor.id && 
          resource.gangwayGroupName === gangwayGroup); 
        return (
          <div
            key={gangwayGroup}
            className={`operationsListModal${isResourceFound ? 'Border' : ''}`}
          >
            <div className="chartTitle"> Gangway Type: {gangwayGroup} </div>
            <ComposedChart
              width={300}
              height={100}
              data={gangwayDataForDoor}
              margin={{
                top: 10, right: 30, left: 0, bottom: 0,
              }}
              onClick={() =>
                handleGraphModalOpen(
                  selectedDoor.id,
                  selectedDoor.name,
                  vessel,
                  gangwayDataForDoor,
                  gangwayGroup,
                )
              }
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis
                dataKey="date"
                tickFormatter={(tick) =>
                  new Date(tick).toLocaleTimeString([], {
                    hour: '2-digit',
                    minute: '2-digit',
                    hour12: false,
                  })
                }
                interval={15}
                tick={{ fontSize: 12 }}
              />
              <YAxis domain={[-40, 40]} allowDataOverflow={true} tick={{ fontSize: 12 }} />
              <Tooltip cursor={false} content={<CustomTooltip />} />
              <Bar
                dataKey="slopeMultiples"
                fill={(entry) => getColorBasedOnValue(entry.slope)}
                barSize={20}
              >
                {gangwayDataForDoor.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={getColorBasedOnValue(entry.slope)}
                    stroke={getColorBasedOnValue(entry.slope)}
                    strokeWidth={2}
                  />
                ))}
              </Bar>
              <ReferenceLine x={vessel.mooringDateTime} stroke="green" label="MT" />
              <ReferenceLine x={vessel.departureDateTime} stroke="red" label="DT" />
              <Line type="monotone" dataKey="slope" stroke="#8884d8" dot={false} strokeWidth={2} />
            </ComposedChart>
          </div>
        );
      })}

    </div>
  );
}
export default OperationsMapShowGraphs;