import React, { useState, useEffect, useContext } from 'react';
import Constants from '../../Constants';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.css';
import DateTime from 'react-datetime';
import moment from 'moment';
import 'moment-timezone';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import UserRoleContext from '../../Contexts/UserRoleContext';
import 'bootstrap/dist/css/bootstrap.css';
import { FaCalendarPlus, FaCalendarTimes, FaSave } from "react-icons/fa";
import useOperationsMapBookingResources from './useOperationsMapBookingResources';
import { checkAuthenticateIssue } from "../Login/LoginUtil";
import { useNavigate } from "react-router-dom";
import { Tooltip } from 'react-tooltip'


const OperationsMapAddGangwayReservationForm = ({ closeModal, bookingId, gangwayGroupName, vesselDoorId, mooringDateTime, departureDateTime, berthName }) => {

    const [formData, setFormData] = useState({
        bookingId: '',
        gangwayGroupName: '',
        vesselDoorId: '',
        startDateTime: '',
        endDateTime: '',
        resourceType: 'Gangway'
    });

    const [errors, setErrors] = useState({});
    const [reservationDateError, setReservationDateError] = useState(false);
    const token = sessionStorage.getItem("jwtToken") || localStorage.getItem("jwtToken");
    const userPortId = sessionStorage.getItem("userPortId") || localStorage.getItem("userPortId");
    const { userRole } = useContext(UserRoleContext);
    const navigate = useNavigate();
    const [availableGangwayNames, setAvailableGangwayNames] = useState([]);
    const {
        addGangwayReservation,
        fetchBookingResources
    } = useOperationsMapBookingResources(token)
    const [selectedGangway, setSelectedGangway] = useState();
    const [showGangways, setShowGangways] = useState(false)

    useEffect(() => {
        if (gangwayGroupName && mooringDateTime && departureDateTime) {

            getAvailableGangwaysPerGroup(gangwayGroupName, mooringDateTime, departureDateTime);

        }
    }, [gangwayGroupName, mooringDateTime, departureDateTime]);

    useEffect(() => {
        console.log("availableGangwayNames.length: ", availableGangwayNames.length)
        console.log('Type:', Array.isArray(availableGangwayNames) ? 'Array' : typeof availableGangwayNames);
        console.log("showGangways: ", showGangways)
    }, [availableGangwayNames]);


    const getAvailableGangwaysPerGroup = async (gangwayGroupName, startDateTime, endDateTime) => {

        const toUtcZFormat = (dateString) => {
            const date = new Date(dateString);
            return date.toISOString(); // Format as ISO 8601 and ensure 'Z' without milliseconds
        };

        const adjustedStartDateTime = toUtcZFormat(startDateTime)
        const adjustedEndDateTime = toUtcZFormat(endDateTime)
        //console.log("url for getting available gangways: ", `${Constants.BACK_END}/api/Gangway/groups/${gangwayGroupName}/startDateTime/${adjustedStartDateTime}/endDateTime/${adjustedEndDateTime}`)

        try {
            const response = await axios.get(`${Constants.BACK_END}/api/Gangway/groups/${gangwayGroupName}/startDateTime/${adjustedStartDateTime}/endDateTime/${adjustedEndDateTime}`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                }
            });
            if (response.data.message) {
                setAvailableGangwayNames([]);
                setShowGangways(false)
                //console.log("empty")
            } else {
                setAvailableGangwayNames(response.data);
                setShowGangways(true)
                //console.log("not empty: ", response.data.message)
            }
            //setAvailableGangwayNames(response.data);
            //console.log("gangwaysPerGroup: ", response.data)
        } catch (error) {
            if (checkAuthenticateIssue(error)) {
                navigate("/Login2");
            } else {
                console.error("Error fetching gangway data:", error);
            }
        }
    };

    useEffect(() => {
        setFormData(prevFormData => ({
            ...prevFormData,
            bookingId: bookingId,
            gangwayGroupName: gangwayGroupName,
            vesselDoorId: vesselDoorId,
            startDateTime: mooringDateTime,
            endDateTime: departureDateTime
        }));
    }, [bookingId, gangwayGroupName]);

    const handleDateChange = (date, id) => {
        const currentFormDate = formData[id] ? moment(formData[id]) : moment();

        const newTime = date ? moment(date).format('HH:mm:ss') : '';
        const updatedDate = currentFormDate.set({
            hour: moment(date).get('hour'),
            minute: moment(date).get('minute'),
            second: moment(date).get('second')
        }).format('YYYY-MM-DDTHH:mm:ss');

        setFormData(prevFormData => {
            const newFormData = { ...prevFormData, [id]: updatedDate };

            if (newFormData.startDateTime && newFormData.endDateTime) {
                setReservationDateError(moment(newFormData.startDateTime).isSameOrAfter(moment(newFormData.endDateTime)));
            }
            getAvailableGangwaysPerGroup(gangwayGroupName, newFormData.startDateTime, newFormData.endDateTime)
            return newFormData;
        });


    };

    const handleChange = (e) => {
        const { id, value } = e.target;
        setFormData(prevFormData => ({
            ...prevFormData,
            [id]: value.slice(0, 500)
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!["PortAdmin"].includes(userRole)) {
            toast.error('You do not have the necessary permissions to add this reservation.', {
                position: "top-center",
                hideProgressBar: true
            });
            return;
        }
        if (validateForm()) {
            try {
                console.log("formData: ", formData)
                await addGangwayReservation(formData, closeModal);
                fetchBookingResources(formData.bookingId);
            } catch (error) {
                console.error("Error updating gangway reservation:", error);
            }
        }
    };

    const validateForm = () => {
        const newErrors = {};
        if (!formData.startDateTime) { newErrors.startDateTime = 'Start Time is required'; }
        if (formData.startDateTime && formData.endDateTime && moment(formData.startDateTime).isSameOrAfter(moment(formData.endDateTime))) { newErrors.startDateTime = 'Start Time is after end time'; }
        if (!formData.endDateTime) { newErrors.endDateTime = 'End Time is required'; }
        if (!formData.gangwayId) {newErrors.gangwayId = 'Choose a gangway';}
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const isValidTimeRange = (current) => {
        return current.isSameOrAfter(mooringDateTime) && current.isBefore(departureDateTime)
    }
    const isValidStartTime = (current) => {
        return current.isSameOrAfter(mooringDateTime)
    }

    return (
        <div>
            <form id="bookingsUpdateModal1" onSubmit={handleSubmit}>
                <div className="row">
                    <div className="col-md-3">
                        <label className="modalLables" htmlFor="startDateTime">Start Time <span className="required">*</span></label>
                        <DateTime
                            dateFormat={false}
                            isValidDate={isValidStartTime}
                            className={`form-control ${errors.startDateTime ? 'is-invalid' : ''}`}
                            value={formData.startDateTime ? moment(formData.startDateTime) : ''}
                            onChange={date => handleDateChange(date, "startDateTime")}
                        />

                    </div>
                    <div className="col-md-3">
                        <label className="modalLables" htmlFor="endTime">End Time <span className="required">*</span></label>
                        <DateTime
                            dateFormat={false}
                            className={`form-control ${errors.endDateTime ? 'is-invalid' : ''}`}
                            value={formData.endDateTime ? moment(formData.endDateTime) : ''}
                            onChange={date => handleDateChange(date, "endDateTime")}
                        />
                        {errors.endDateTime && <div className="invalid-feedback">{errors.endDateTime}</div>}
                    </div>

                    <div className="col-md-3">
                        <label className="modalLables" htmlFor="gangwayId">Gangway Name<span className="required">*</span></label>
                        <select
                            className={`form-control ${errors.gangwayId ? 'is-invalid' : ''}`}
                            id="gangwayId"
                            value={formData.gangwayId}
                            onChange={handleChange}
                        >
                            <option value="">Select Gangway</option>
                            {showGangways === false && (
                                <option value="" disabled>
                                    No gangways available
                                </option>
                            )}
                            {showGangways === true && Array.isArray(availableGangwayNames) && availableGangwayNames.length > 0 &&
                                availableGangwayNames.map((gangway, index) => (
                                    <option key={index} value={gangway.id}>
                                        {gangway.name}
                                    </option>
                                ))
                            }
                        </select>
                        {errors.vesselName && <div className="invalid-feedback">{errors.vesselName}</div>}
                    </div>

                    <div className="col-md-3 d-flex align-items-center">
                        <button data-tooltip-id="operationsMapAddGangwayReservationFormIcons"
                                data-tooltip-content="Save Reservation" 
                                type="submit" className="btn gangReserve"><FaSave /></button>
                        {/* <button type="button" className="btn gangNoReserve" onClick={closeModal}><FaCalendarTimes /></button> */}
                        {errors.startDateTime && <div className='alert alert-danger' >{errors.startDateTime}</div>}
                    </div>
                    <Tooltip
                     id="operationsMapAddGangwayReservationFormIcons"
                     place="right"
                     className="operationsMapAddGangwayReservationFormTooltip"
                    />
                </div>
            </form>
        </div>

    )
}
export default OperationsMapAddGangwayReservationForm;