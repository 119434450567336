import React, { useContext, useEffect, useState, useMemo, useCallback } from 'react';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.css';
import BookingsUpdateModal from './BookingsUpdateModal';
import AddNewBookingModal from './BookingsAddNewModal';
import { useUserRoleContext } from '../../Contexts/useUserRoleContext';
import Constants from '../../Constants';
import { useTable, useSortBy, usePagination, useGlobalFilter } from 'react-table';
import { Columns } from './BookingsTableColumns';
import { FaSort, FaAngleRight, FaAngleLeft, FaRegCalendarAlt, FaRegListAlt, FaEye, FaEyeSlash } from "react-icons/fa";
import { FaRegSquarePlus } from "react-icons/fa6";
import GlobalFilter from './BookingsTableGlobalFilter';
import DateTime from 'react-datetime';
import "react-datetime/css/react-datetime.css";
import { useNavigate } from "react-router-dom";
import { checkAuthenticateIssue } from "../Login/LoginUtil";
import useRefreshToken from "../../Contexts/useRefreshToken";
import { fetchBookingList } from '../../Services/ApiServices';
import BookingsCalendar from './BookingsCalendar';
import { Tooltip } from 'react-tooltip'


function Bookings({ userPortName }) {
    const [bookings, setBookings] = useState([]);
    const [hideCancelled, setHideCancelled] = useState(false);

    const [selectedBooking, setSelectedBooking] = useState(null);
    const [vesselData, setVesselData] = useState(null);
    const [showBookingsUpdateModal, setShowBookingsUpdateModal] = useState(false);
    const [showNewBookingModal, setShowNewBookingModal] = useState(false);
    const { userRole, setUserRole } = useUserRoleContext();
    const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [showCalendar, setShowCalendar] = useState(false)
    const [showList, setShowList] = useState(true)
    const thisYear = new Date().getFullYear();
    const yearsForDropdown = Array.from(new Array(50), (val, index) => index + 2024);
    const token = sessionStorage.getItem("jwtToken") || localStorage.getItem("jwtToken");
    const userPortId = sessionStorage.getItem("userPortId") || localStorage.getItem("userPortId");
    const navigate = useNavigate();

    useRefreshToken();

    useEffect(() => {
        // if (userRole) {
        fetchBookingListThisYear();
        // }
    }, []);

    const fetchBookingList = async (url) => {
        try {
            const response = await axios.get(url, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            });
            setBookings(response.data);
            console.log("Bookings fetched:", response.data);
        } catch (error) {
            if (checkAuthenticateIssue(error)) {
                navigate("/Login2");
            }
            console.error("Error fetching bookings:", error);
        }
    };

    const showCalendarView = () => {
        setShowCalendar(true)
        setShowList(false)
    }

    const showListView = () => {
        setShowCalendar(false)
        setShowList(true)
    }

    const fetchBookingListThisYear = () => {
        const url = userRole === "Admin"
            ? `${Constants.BACK_END}/api/Booking/year/${thisYear}`
            : `${Constants.BACK_END}/api/Booking/year/${thisYear}/portId/${userPortId}`;
        fetchBookingList(url);
    };

    const fetchBookingListSelectedYear = (year) => {
        const url = userRole === "Admin"
            ? `${Constants.BACK_END}/api/Booking/year/${year}`
            : `${Constants.BACK_END}/api/Booking/year/${year}/portId/${userPortId}`;
        fetchBookingList(url);
    };

    const fetchBookingListAllYears = () => {
        const url = userRole === "Admin"
            ? `${Constants.BACK_END}/api/Booking`
            : `${Constants.BACK_END}/api/Booking/${userPortId}`;
        fetchBookingList(url);
    };

    const fetchBookingListSelectedDate = (date) => {
        const url = userRole === "Admin"
            ? `${Constants.BACK_END}/api/Booking/date/${date}`
            : `${Constants.BACK_END}/api/Booking/date/${date}/portId/${userPortId}`;
        fetchBookingList(url);
    };

    const handleSelect = useCallback((booking) => {
        setSelectedBooking(booking);
        setShowBookingsUpdateModal(true);
        populateVessel(booking.vesselId);
    }, []);

    const handleYearChange = useCallback((event) => {
        const year = event.target.value;
        setSelectedYear(year);
        fetchBookingListSelectedYear(year);
    }, []);

    const handleDateChange = (date) => {
        setSelectedDate(date);
        const formatedDate = date.toISOString().slice(0, 10);
        fetchBookingListSelectedDate(formatedDate);
    }

    const fetchBookingListToday = () => {
        const today = new Date();
        setSelectedDate(today);
        const formatedDate = today.toISOString().slice(0, 10);
        fetchBookingListSelectedDate(formatedDate);
    }

    const clearSelections = () => {
        setSelectedBooking(null);
        setVesselData(null);
    };

    const closeModal = () => {
        setShowBookingsUpdateModal(false);
        setShowNewBookingModal(false);
    };

    const populateVessel = async (Id) => {
        try {
            const response = await axios.get(`${Constants.BACK_END}/api/Vessel/${Id}`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                }
            });
            setVesselData(response.data);
        } catch (error) {
            if (checkAuthenticateIssue(error)) {
                navigate("/Login");
            } else {
                console.error("Error fetching vessel data:", error);
            }
        }
    };

    const columns = useMemo(() => Columns({ handleSelect }), [])

    const data = bookings

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        state,
        setGlobalFilter,
        rows,
        page,
        nextPage,
        previousPage,
        canNextPage,
        canPreviousPage,
        pageOptions,
    } = useTable({
        columns,
        data
    },
        useGlobalFilter,
        useSortBy,
        usePagination
    )

    const { globalFilter } = state;
    const { pageIndex } = state;

    return (
        <div>

            <div className='top-container'>
                <div className='top-container-inside'>
                    <h1 className="bookingHeader">Bookings</h1>

                    <button
                        data-tooltip-id="bookingPageIcons"
                        data-tooltip-content="Calendar View"
                        className="btn addBooking"
                        onClick={() => showCalendarView()}>
                        <FaRegCalendarAlt className='calendarViewIcon' />
                    </button>
                    <button
                        data-tooltip-id="bookingPageIcons"
                        data-tooltip-content="Table View"
                        className="btn addBooking"
                        onClick={() => showListView()}>
                        <FaRegListAlt className='listViewIcon' />
                    </button>
                    <button
                        data-tooltip-id="bookingPageIcons"
                        data-tooltip-content="Add Booking"
                        className="btn addBooking" onClick={() => setShowNewBookingModal(true)}>
                        <FaRegSquarePlus className='addBookingIcon' />
                    </button>
                    <div className='top-container-interior'>
                        <button className="btn toggleCancelled" onClick={() => setHideCancelled(prev => !prev)}>
                            {hideCancelled ? (
                                <FaEyeSlash
                                    data-tooltip-id="bookingPageIcons"
                                    data-tooltip-content="View Cancelled Bookings"
                                    data-tooltip-place="top-end"
                                    className="eyeIcon" />
                            ) : (
                                <FaEye
                                    data-tooltip-id="bookingPageIcons"
                                    data-tooltip-content="Hide Cancelled Bookings"
                                    data-tooltip-place="top-end"
                                    className="eyeIcon" />
                            )}

                        </button>
                    </div>
                    <Tooltip
                        id="bookingPageIcons"
                        place="top"
                        className="bookingPageTooltip"
                    />
                </div>
                {showList && <div className='top-container-inside'>
                    <div
                        // data-tooltip-id="bookingPageIcons"
                        // data-tooltip-content="Filter Bookings by Year" 
                        className='top-container-interior'>
                        <button

                            className="btn allYears" onClick={() => fetchBookingListAllYears()}>All Years</button>
                        <button className="btn thisYear" onClick={() => fetchBookingListThisYear()}>This Year</button>
                        <label
                            className="bookingLabels" htmlFor="selectedYear">Select Year:</label>
                        <select
                            className="selectedYear"
                            id="selectedYear"
                            value={selectedYear}
                            onChange={handleYearChange}>
                            {yearsForDropdown.map((year, index) => {
                                return <option key={`year${index}`} value={year}>{year}</option>
                            })
                            }
                        </select>
                    </div>

                    <div
                        // data-tooltip-id="bookingPageIcons"
                        // data-tooltip-content="Filter Bookings by Date"
                        className='top-container-interior'>
                        <button
                            className="btn today" onClick={() => fetchBookingListToday()}>Today</button>
                        <label

                            className="bookingLabels" htmlFor="selectedDate">Select Date:</label>
                        <DateTime
                            className='selectedDate'
                            value={selectedDate}
                            onChange={date => handleDateChange(date)}
                            inputProps={{ placeholder: 'Select Date', readOnly: true }}
                            timeFormat={false}
                        />
                    </div>

                    <div className='top-container-interior'>
                        <>
                            <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />
                        </>
                    </div>
                </div>}
            </div>
            {showCalendar &&
                <BookingsCalendar
                    bookings={bookings}
                    hideCancelled={hideCancelled}

                />}

            {showList && <div className='table-container' >
                <table className="table table-hover" {...getTableProps()}>
                    <thead>
                        {
                            headerGroups.map(headerGroup => {
                                const { key: headerGroupKey, ...headerGroupProps } = headerGroup.getHeaderGroupProps();
                                return (
                                    <tr {...headerGroupProps} key={headerGroupKey}>
                                        {headerGroup.headers.map(column => {
                                            const { key: columnKey, ...columnProps } = column.getHeaderProps(column.getSortByToggleProps());
                                            return (
                                                <th className='operationsPictureBookingTh' key={columnKey} {...columnProps}>
                                                    {column.render('Header')}
                                                    {headerGroup.headers[headerGroup.headers.length - 1] !== column && (
                                                        <span style={{ marginLeft: '5px' }}> <FaSort /> </span>
                                                    )}
                                                </th>
                                            );
                                        })}
                                    </tr>
                                );
                            })
                        }
                    </thead>
                    <tbody {...getTableBodyProps()}>
                        {
                            rows.map(row => {
                                prepareRow(row);
                                const { key, ...rowProps } = row.getRowProps();
                                const bookingStatus = row.original.status;
                                if (hideCancelled && bookingStatus == "CANCELLED") {
                                    return null;
                                }
                                return (
                                    <tr key={row.id} {...rowProps} onClick={() => handleSelect(row.original)}>
                                        {row.cells.map(cell => {
                                            const { key, ...cellProps } = cell.getCellProps();
                                            return (
                                                <td key={key} className='operationsPictureBookingTd' {...cellProps}>
                                                    {cell.render('Cell')}
                                                </td>
                                            );
                                        })}
                                    </tr>
                                );
                            })
                        }
                    </tbody>
                </table>
            </div>}

            {showBookingsUpdateModal && (
                <BookingsUpdateModal
                    booking={selectedBooking}
                    closeModal={closeModal}
                    fetchBookingListThisYear={fetchBookingListThisYear}
                    clearSelections={clearSelections}
                />
            )}
            {showNewBookingModal && (
                <AddNewBookingModal
                    closeModal={closeModal}
                    fetchBookingListThisYear={fetchBookingListThisYear}
                />
            )}

        </div>
    );
}

export default Bookings;