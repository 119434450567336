export const vesselFactors = {
  // factors for full hd are 0.501 and 0.775
  // factors for original were 1.34 and 2.06
  // factors for master are 2.67 and 4.12
  // factors for qhd are 0.667 and 1.033
    1: 0.775, //ADVENTURE OF THE SEAS
    2: 0.501, //AMADEA
    3: 0.501, //AMBIENCE
    4: 0.501, //AMERA
    5: 0.501, //ARCADIA
    6: 0.770, //AURORA
    7: 0.775, //AZAMARA PURSUIT
    8: 0.501, //BAIE ST PAUL
    9: 0.785, //BOLETTE
    10: 0.501, //CARIBBEAN PRINCESS
    11: 0.775, //CARNIVAL SUNSHINE        no picture
    12: 0.775, //CARNIVAL VENEZIA         no picture
    13: 0.501, //CELEBRITY ECLIPSE
    14: 0.775, //CELEBRITY SUMMIT
    15: 0.775, //CROWN PRINCESS
    16: 0.775, //CRYSTAL SERENITY
    17: 0.501, //DISNEY DREAM
    18: 0.501, //DISNEY FANTASY
    19: 0.501, //DISNEY MAGIC
    20: 0.501, //DISNEY WONDER
    21: 0.501, //EMERALD PRINCESS
    22: 0.775, //EMPRESS OF THE SEAS
    23: 0.775, //ENCHANTMENT OF THE SEAS
    24: 0.785, //EUROPA
    25: 0.501, //EXPLORA 1
    26: 0.785, //EXPLORER OF THE SEAS
    27: 0.775, //GRANDEUR OF THE SEAS
    28: 0.81, //GRANDIOSA
    29: 0.501, //HANSEATIC INSPIR
    30: 0.775, //HANSEATIC NATURE
    31: 0.775, //INSIGNIA
    32: 0.775, //IRVING ACADIAN           no picture
    33: 0.775, //JEWEL OF THE SEAS        no picture
    34: 0.501, //LE BELLOT
    35: 0.775, //MAJESTIC PRINCESS        no picture
    36: 0.775, //MARCO POLO
    37: 0.775, //MARINA                   no picture
    38: 0.785, //MARINER OF THE SEAS
    39: 0.785, //MEIN SCHIFF 1
    40: 0.785, //MEIN SCHIFF 2
    41: 0.775, //MEIN SCHIFF 3
    42: 0.775, //MEIN SCHIFF 4
    43: 0.775, //MEIN SCHIFF 5
    44: 0.775, //MEIN SCHIFF 6
    45: 0.510, //MERAVIGLIA
    46: 0.505, //MSC POESIA
    47: 0.785, //NAVIGATOR OF THE SEAS
    48: 0.775, //NIEUW STATENDAM
    49: 0.820, //NORWEGIAN BLISS
    50: 0.820, //NORWEGIAN ENCORE
    51: 0.775, //NORWEGIAN GEM
    52: 0.501, //NORWEGIAN JADE
    53: 0.775, //NORWEGIAN JEWEL            no picture
    54: 0.820, //NORWEGIAN JOY
    55: 0.775, //NORWEGIAN PEARL
    56: 0.505, //NORWEGIAN SKY
    57: 0.565, //OASIS OF THE SEAS
    58: 0.501, //OCEAN ENDEAVOUR
    59: 0.501, //OCEAN EXPLORER
    60: 0.501, //OCEAN NAVIGATOR
    61: 0.775, //OCEAN VOYAGER               no picture
    62: 0.501, //PEARL MIST
    63: 0.775, //QUEEN MARY 2               no picture
    64: 0.775, //RADIANCE CLASS SHIPS
    65: 0.775, //RCYC EVRIMA
    66: 0.810, //ROYAL PRINCESS
    67: 0.775, //SAPPHIRE PRINCESS           no picture
    68: 0.775, //SCENIC ECLIPSE
    69: 0.501, //SEA DISCOVERER
    70: 0.501, //SEABOURN ODYSSEY
    71: 0.365, //SEABOURN OVATION           
    72: 0.775, //SEABOURN QUEST
    73: 0.501, //SEABOURN SOJOURN
    74: 0.775, //SEVEN SEAS MARINER
    75: 0.501, //SEVEN SEAS NAVIGATOR
    76: 0.775, //SILVER SHADOW
    77: 0.777, //SILVER WHISPER
    78: 0.501, //SKY PRINCESS
    79: 0.501, //SPIRIT OF ADVENTURE
    80: 0.501, //THUNDER BAY
    81: 0.501, //VIKING JUPITER
    82: 0.501, //VIKING MARS
    83: 0.501, //VIKING OCTANTIS
    84: 0.501, //VIKING ORION
    85: 0.501, //VIKING POLARIS
    86: 0.501, //VIKING SEA
    87: 0.501, //VIKING SKY
    88: 0.501, //VIKING STAR
    89: 0.501, //VIKING VENUS
    90: 0.810, //VIRTUOSA
    91: 0.365, //VOLENDAM
    92: 0.775, //VOYAGER OF THE SEAS
    93: 0.775, //WORLD DREAM
    94: 0.775, //ZAANDAM
    95: 0.365, //ZUIDERDAM
  };

  export const vesselDoors = {
    1: [
      //adjusted when boat factor was set to 1     /////    comments at end of line reflect door in database
      { id: 738, name: "9", orientation: "PORT", x: 290, y: 23, width: 45, height: 20 }, // 9 port 3.05
      { id: 737, name: "7", orientation: "PORT", x: 395, y: 23, width: 45, height: 20 }, // 7 port 3.05
      { id: 736, name: "1", orientation: "PORT", x: 660, y: 23, width: 45, height: 20 }, //1 port 3.05
      { id: 742, name: "9", orientation: "PORT", x: 290, y: 88, width: 45, height: 20 }, //9 star 3.05
      { id: 741, name: "7", orientation: "PORT", x: 395, y: 88, width: 45, height: 20 }, //7 star 3.05 
      { id: 740, name: "DECK 2", orientation: "PORT", x: 570, y: 88, width: 85, height: 20 }, //Deck2 star 6.05
      { id: 739, name: "1", orientation: "PORT", x: 660, y: 88, width: 45, height: 20 }, // 1 start 3.05

      { id: 738, name: "9", orientation: "STARBOARD", x: 605, y: 88, width: 45, height: 20 },// 9 port 3.05 
      { id: 737, name: "7", orientation: "STARBOARD", x: 500, y: 88, width: 45, height: 20 }, // 7 port 3.05
      { id: 736, name: "1", orientation: "STARBOARD", x: 230, y: 88, width: 45, height: 20 }, //1 port 3.05
      { id: 742, name: "9", orientation: "STARBOARD", x: 605, y: 23, width: 45, height: 20 }, //9 star 3.05
      { id: 741, name: "7", orientation: "STARBOARD", x: 500, y: 23, width: 45, height: 20 }, //7 star 3.05
      { id: 740, name: "Deck 2", orientation: "STARBOARD", x: 330, y: 23, width: 85, height: 20 }, //Deck2 star 6.05
      { id: 736, name: "1", orientation: "STARBOARD", x: 230, y: 23, width: 45, height: 20 }, // 1 start 3.05
    ],
    2: [
      //adjusted when boat factor was set to 1
      { id: 743, name: "Gangway1", orientation: "PORT", x: 200, y: 30, width: 150, height: 20 }, //gangway1 port 10.1
      { id: 744, name: "Ac.Lad", orientation: "PORT", x: 450, y: 30, width: 210, height: 20 }, //accom ladder port 7.1
      { id: 745, name: "Gangway2", orientation: "PORT", x: 400, y: 55, width: 150, height: 20 }, //gangway2 port 3.9
      { id: 746, name: "Gangway1", orientation: "PORT", x: 200, y: 80, width: 150, height: 20 }, //gangway1 star 10.1
      { id: 747, name: "Ac.Lad", orientation: "PORT", x: 450, y: 80, width: 210, height: 20 }, //accom ladder star 7.1
      // { id: 748, name: "Gangway2", orientation: "PORT", x: 520, y: 110, width: 120, height: 20 }, // gangway2 star 3.9

      { id: 743, name: "Gangway1", orientation: "STARBOARD", x: 655, y: 82, width: 150, height: 20 }, //gangway1 port 10.1
      { id: 744, name: "Ac.Lad", orientation: "STARBOARD", x: 410, y: 82, width: 210, height: 20 }, //accom ladder port 7.1
      { id: 745, name: "Gangway2", orientation: "STARBOARD", x: 460, y: 57, width: 150, height: 20 }, //gangway2 port 3.9
      { id: 746, name: "Gangway1", orientation: "STARBOARD", x: 655, y: 32, width: 150, height: 20 }, //gangway1 star 10.1
      { id: 747, name: "Ac.Lad", orientation: "STARBOARD", x: 410, y: 32, width: 210, height: 20 }, //accom ladder star 7.1
      // { id: 748, name: "Gangway2", orientation: "STARBOARD", x: 0, y: 150, width: 120, height: 20 }, // gangway2 star 3.9
    ],
    30: [
      //adjusted when boat factor was set to 1
      { id: 953, name: "#9", orientation: "PORT", x: 205, y: 16, width: 45, height: 20 }, //#9 port 5
      { id: 954, name: "#12-1", orientation: "PORT", x: 84, y: 16, width: 45, height: 20 }, //#12-1 port 5
      { id: 955, name: "#12-2", orientation: "PORT", x: 126, y: 16, width: 45, height: 20 }, //#12-2 port 8.15
      { id: 956, name: "#9", orientation: "PORT", x: 205, y: 48, width: 45, height: 20 }, //#9 star 5
      { id: 957, name: "#12-1", orientation: "PORT", x: 84, y: 48, width: 45, height: 20 }, //#12-1 star 5 
      { id: 958, name: "#12-2", orientation: "PORT", x: 126, y: 48, width: 45, height: 20 }, //#12-2  star 8.15

      { id: 953, name: "#9", orientation: "STARBOARD", x: 205, y: 48, width: 45, height: 20  }, //#9 port 5
      { id: 954, name: "#12-1", orientation: "STARBOARD", x: 290, y: 48, width: 45, height: 20 }, //#12-1 port 5
      { id: 955, name: "#12-2", orientation: "STARBOARD", x: 330, y: 48, width: 45, height: 20 }, //#12-2 port 8.15
      { id: 956, name: "#9", orientation: "STARBOARD", x: 205, y: 16, width: 45, height: 20 }, //#9 star 5
      { id: 957, name: "#12-1", orientation: "STARBOARD", x: 290, y: 16, width: 45, height: 20 }, //#12-1 star 5
      { id: 958, name: "#12-2", orientation: "STARBOARD", x: 330, y: 16, width: 45, height: 20 }, //#12-2 star 8.15
    ],

    //ZUIDERDAM
    95: [
      //adjusted when boat factor was set to 1
      { id: 1458, name: "#9", orientation: "PORT", doorHeight: 2.8, x: 1300, y: 66, width: 120, height: 40 }, //#9 port 2.8
      { id: 1459, name: "#11", orientation: "PORT", doorHeight: 2.8,x: 1135, y: 66, width: 120, height: 40 }, //#11 port 2.8
      { id: 1460, name: "#13", orientation: "PORT", doorHeight: 2.8,x: 852, y: 66, width: 120, height: 40 }, //#13 port 2.8
      { id: 1461, name: "#25", orientation: "PORT", doorHeight: 8.4,x: 852, y: 33, width: 120, height: 40 }, //#25 port 8.4
      { id: 1462, name: "#21", orientation: "PORT", doorHeight: 5.7,x: 670, y: 66, width: 120, height: 40 }, //#21 port 5.7 
      { id: 1463, name: "#9", orientation: "PORT", doorHeight: 2.8,x: 1300, y: 141, width: 120, height: 40 }, //#9 star 2.8
      { id: 1464, name: "#11", orientation: "PORT", doorHeight: 2.8,x: 1135, y: 141, width: 120, height: 40 }, //#11 star 2.8
      { id: 1465, name: "#13", orientation: "PORT", doorHeight: 2.8,x: 852, y: 141, width: 120, height: 40 }, //#13 star 2.8
      { id: 1466, name: "#25", orientation: "PORT", doorHeight: 8.4,x: 852, y: 175, width: 120, height: 40 }, //#25 star 8.4
      { id: 1467, name: "#21", orientation: "PORT", doorHeight: 5.7,x: 670, y: 141, width: 120, height: 40 }, //#21 star 5.7 
      
      { id: 1458, name: "#9", orientation: "STARBOARD", doorHeight: 2.8, x: 445, y: 66, width: 120, height: 40 }, //#9 port 2.8
      { id: 1459, name: "#11", orientation: "STARBOARD", doorHeight: 2.8, x: 605, y: 66, width: 120, height: 40 }, //#11 port 2.8
      { id: 1460, name: "#13", orientation: "STARBOARD", doorHeight: 2.8, x: 888, y: 66, width: 120, height: 40 }, //#13 port 2.8
      { id: 1461, name: "#25", orientation: "STARBOARD", doorHeight: 8.4, x: 888, y: 33, width: 120, height: 40 }, //#25 port 8.4
      { id: 1462, name: "#21", orientation: "STARBOARD", doorHeight: 5.7, x: 1070, y: 66, width: 120, height: 40 }, //#21 port 5.7 
      { id: 1463, name: "#9", orientation: "STARBOARD", doorHeight: 2.8, x: 445, y: 141, width: 120, height: 40 }, //#9 star 2.8
      { id: 1464, name: "#11", orientation: "STARBOARD", doorHeight: 2.8, x: 605, y: 141, width: 120, height: 40 }, //#11 star 2.8
      { id: 1465, name: "#13", orientation: "STARBOARD", doorHeight: 2.8, x: 888, y: 141, width: 120, height: 40 }, //#13 star 2.8
      { id: 1466, name: "#25", orientation: "STARBOARD", doorHeight: 8.4, x: 888, y: 175, width: 120, height: 40 }, //#25 star 8.4
      { id: 1467, name: "#21", orientation: "STARBOARD", doorHeight: 5.7, x: 1070, y: 141, width: 120, height: 40 }, //#21 star 5.7 

     // { id: 3777, name: "18SC", orientation: "PORT", x: 256, y: 65, width: 220, height: 40 }, //#18SC  port 3.4
     // { id: 3777, name: "18SC", orientation: "STARBOARD", x: 1480, y: 141, width: 220, height: 40 }, //#18SC  port 3.4
    ],

    //VOLENDAM
    91: [
      //adjusted when boat factor was set to 1
      { id: 1425, name: "#3", orientation: "PORT", x: 864, y: 68, width: 110, height: 40 }, //#3 port 3.4
      { id: 1426, name: "#5", orientation: "PORT", x: 810, y: 33, width: 120, height: 40 }, //#5 port 3.4
      { id: 1427, name: "#14", orientation: "PORT", x: 250, y: 53, width: 120, height: 40 }, //#14 port 3.4
      { id: 1428, name: "#19", orientation: "PORT", x: 1015, y: 53, width: 120, height: 40 }, //#19 port 9
      { id: 1429, name: "#4", orientation: "PORT", x: 864, y: 141, width: 120, height: 40 }, //#4 star 3.4
      { id: 1430, name: "#6", orientation: "PORT", x: 810, y: 175, width: 120, height: 40 }, //#6 star 3.4
      { id: 1431, name: "#15", orientation: "PORT", x: 250, y: 153, width: 120, height: 40 }, //#15 star 3.4
      { id: 1432, name: "#18", orientation: "PORT", x: 625, y: 153, width: 120, height: 40 }, //#18 star 6.2
      { id: 1433, name: "#20", orientation: "PORT", x: 1015, y: 155, width: 120, height: 40 }, //#20 star 9
      
      { id: 1425, name: "#3", orientation: "STARBOARD", x: 592, y: 141, width: 110, height: 40 }, //#3 port 3.4
      { id: 1426, name: "#5", orientation: "STARBOARD", x: 640, y: 175, width: 120, height: 40 }, //#5 port 3.4
      { id: 1427, name: "#14", orientation: "STARBOARD", x: 1210, y: 153, width: 120, height: 40 }, //#14 port 3.4
      { id: 1428, name: "#19", orientation: "STARBOARD", x: 440, y: 153, width: 120, height: 40 }, //#19 port 9
      { id: 1429, name: "#4", orientation: "STARBOARD", x: 592, y: 66, width: 120, height: 40 }, //#4 star 3.4
      { id: 1430, name: "#6", orientation: "STARBOARD", x: 640, y: 33, width: 120, height: 40 }, //#6 star 3.4
      { id: 1431, name: "#15", orientation: "STARBOARD", x: 1210, y: 53, width: 120, height: 40 }, //#15 star 3.4
      { id: 1432, name: "#18", orientation: "STARBOARD", x: 830, y: 53, width: 120, height: 40 }, //#18 star 6.2
      { id: 1433, name: "#20", orientation: "STARBOARD", x: 440, y: 53, width: 120, height: 40 }, //#20 star 9

     // { id: 3778, name: "SC", orientation: "PORT", x: 510, y: 53, width: 220, height: 40 }, //SC  port 3.4
     // { id: 3778, name: "SC", orientation: "STARBOARD", x: 940, y: 153, width: 220, height: 40 }, //SC  port 3.4
    ],

    //SEABOURN OVATION
    71: [
      //adjusted when boat factor was set to 1
      { id: 1277, name: "D", orientation: "PORT", x: 250, y: 53, width: 110, height: 40 }, //D port 2.1
      { id: 1278, name: "M", orientation: "PORT", x: 440, y: 53, width: 120, height: 40 }, //M port 7.9
      { id: 1279, name: "H", orientation: "PORT", x: 680, y: 53, width: 120, height: 40 }, //H port 7.9
      { id: 1280, name: "D", orientation: "PORT", x: 250, y: 143, width: 120, height: 40 }, //D star 2.1
      { id: 1281, name: "M", orientation: "PORT", x: 440, y: 131, width: 120, height: 40 }, //M star 7.9
      { id: 1282, name: "H", orientation: "PORT", x: 680, y: 131, width: 120, height: 40 }, //H star 7.9
      { id: 1283, name: "K", orientation: "PORT", x: 980, y: 131, width: 120, height: 40 }, //K star 5

      { id: 1277, name: "D", orientation: "STARBOARD", x: 1040, y: 131, width: 110, height: 40 }, //D port 2.1
      { id: 1278, name: "M", orientation: "STARBOARD", x: 850, y: 131, width: 120, height: 40 }, //M port 7.9
      { id: 1279, name: "H", orientation: "STARBOARD", x: 610, y: 131, width: 120, height: 40 }, //H port 7.9
      { id: 1280, name: "D", orientation: "STARBOARD", x: 1040, y: 43, width: 120, height: 40 }, //D star 2.1
      { id: 1281, name: "M", orientation: "STARBOARD", x: 850, y: 53, width: 120, height: 40 }, //M star 7.9
      { id: 1282, name: "H", orientation: "STARBOARD", x: 610, y: 53, width: 120, height: 40 }, //H star 7.9
      { id: 1283, name: "K", orientation: "STARBOARD", x: 305, y: 53, width: 120, height: 40 }, //K star 5


      //{ id: 3779, name: "SC", orientation: "PORT", x: 210, y: 107, width: 220, height: 40 }, //SC  port 3.4
     // { id: 3779, name: "SC", orientation: "STARBOARD", x: 1080, y: 75, width: 220, height: 40 }, //SC  port 3.4
    ]
};

export const vesselPowerDoors = {
  //ZUIDERDAM
  95: [
    //adjusted when boat factor was set to 1
    { id: 1470, name: "SC", orientation: "PORT", x: 256, y: 65, width: 220, height: 40 }, //SC  port 0.8
    { id: 1470, name: "SC", orientation: "STARBOARD", x: 1480, y: 141, width: 220, height: 40 }, //SC  port 0.8
  ],

  //VOLENDAM
  91: [
    //adjusted when boat factor was set to 1
    { id: 1469, name: "SC", orientation: "PORT", x: 510, y: 53, width: 220, height: 40 }, //SC  port 3.2
    { id: 1469, name: "SC", orientation: "STARBOARD", x: 940, y: 153, width: 220, height: 40 }, //SC  port 3.2
  ],

  //SEABOURN OVATION
  71: [
    //adjusted when boat factor was set to 1
    { id: 1468, name: "SC", orientation: "PORT", x: 210, y: 107, width: 220, height: 40 }, //SC  port 2.1
    { id: 1468, name: "SC", orientation: "STARBOARD", x: 1080, y: 75, width: 220, height: 40 }, //SC  port 2.1
  ]
}
